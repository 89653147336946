import {HoursList} from "../Hours/HoursList";

export const HoursView = () => {
     return <>
            <div
                // className="d-flex justify-content-center"
            >
                <HoursList/>
            </div>
    </>
}
